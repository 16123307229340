import $ from "jquery";

$.fn.scrollTo = function(offsetTop, callback) {
    if ($(this).length) {
        offsetTop = typeof offsetTop !== 'undefined' ? offsetTop : 0;
        var top = $(this).eq(0).offset().top-offsetTop;
        $('html, body').animate({
            scrollTop : top
        },500);

        if (typeof callback === 'function') setTimeout(callback, 500);
    }
}
