import $ from "jquery";
window.$ = window.jQuery = $;
import "waypoints/lib/noframework.waypoints.js";
import "slick-carousel";
import * as parallax from "./parallax";
import "./tools";
import "./bgmousemove";
import "./tabs";
import "@fortawesome/fontawesome-free/js/fontawesome.js";
import "@fortawesome/fontawesome-free/js/solid.js";
import {transformElementonMousemove} from "./bgmousemove";

$(document).ready(function() {

	// HTML transforms
    $('.doc table').each(function() {
    	$(this).wrap('<div class="table-wrapper">');
    });
	$(document).on('click', 'figure.can-fullscreen', function() {
		$(this).toggleClass('is-fullscreen');
	});

	// Scroll animation effects
    parallax.init();

	// Background effects
	$('#home .container-bg > img').each(function() {
		transformElementonMousemove(this);
	});

	// Sliders
	$('.slick').each(function() {
		const slider = $(this);
		slider.slick({
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			prevArrow: slider.siblings('.slick-arrow-left'),
			nextArrow: slider.siblings('.slick-arrow-right'),
			draggable: true,
			speed: 750,
			autoplaySpeed: 5000
		});
	});

	// override smooth scrolling on load
	const hash = window.location.hash;
	if (hash) {
		const element = document.getElementById(hash.slice(1));
		if (element) {
			const offsetTop = element.offsetTop;
			window.scrollTo({
				top: offsetTop,
				behavior: 'instant'
			});
		}
	}

	// Mobile navigation
	$('.nav-burger').on('click', function(e) {
		e.preventDefault();
		$(this).parents('.nav').toggleClass('nav--opened');
	});
	$('.nav-item a').on('click', function(e) {
		const navitem = $(this);
		const subnav = navitem.siblings('.nav-subitems');
		if (subnav.length && $('.nav-burger').is(':visible')) {
			e.preventDefault();
			if (subnav.is(':visible')) {
				navitem.removeClass('active');
				subnav.slideUp();
			} else {
				navitem.addClass('active');
				subnav.slideDown();
			}
		}
	});

	$(document).on('click', 'main, .nav-wrapper a:not(.nav-burger)', function() {
		const subnav = $(this).siblings('.nav-subitems');
		if (!subnav.length) {
			$('.nav').removeClass('nav--opened');
		}
	}).on('click', '.siteswapper-button', function() {
		$(this).closest('.siteswapper').addClass('siteswapper--extended');
	});

});
