export function transformElementonMousemove(element) {
    element.style.transform  = 'scale(1.1) rotateX(0) rotateY(0)';
    $('body').on('mousemove', (e) => {
        window.requestAnimationFrame(function() {
            transformElement(element, e.clientX, e.clientY);
        });
    });
}

function transformElement(element, x, y) {
    var scale = 1.1;
    var box = element.getBoundingClientRect();
    if (isInViewport(element)) {
        var calcX = -(y - box.y - (box.height / 2)) / (window.innerHeight / 10);
        var calcY = (x - box.x - (box.width / 2)) / (window.innerWidth / 15);
        element.style.transform = 'scale('+scale+') rotateX(' + calcX + 'deg) ' + 'rotateY(' + calcY + 'deg)';
    }
}

function isInViewport(element) {
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
};