import { TweenMax, TimelineMax } from "gsap";
import ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

export function init() {
	startParallax();
}

const controller = new ScrollMagic.Controller({
	globalSceneOptions: {
		offset: -1
	}
});

function startParallax() {

	const navTrigger = document.querySelector('[data-fixnav]');

	if (document.querySelector('.nav--main') && navTrigger) {
		new ScrollMagic.Scene({
			triggerElement: navTrigger,
			triggerHook: 0
		})
		.setClassToggle(".nav--main", "nav--fixed")
	    .addTo(controller);
	}

	if (document.querySelector('.siteswapper') && navTrigger) {
		new ScrollMagic.Scene({
			triggerElement: navTrigger,
			triggerHook: 0
		})
		.setClassToggle(".siteswapper", "siteswapper--show")
		.addTo(controller);
	}

	document.querySelectorAll('.parallax').forEach(function(element) {

		const data= element.dataset;

		const translateYIn = data.translateyIn || 0;
		const translateYOut = data.translateyOut || 0;
		const translateXIn = data.translatexIn || 0;
		const translateXOut = data.translatexOut || 0;
		const opacityIn = data.opacityIn || 1;
		const opacityOut = data.opacityOut || 1;

		const elHeight = element.clientHeight;
		const windowHeight = window.innerHeight;
		let proportion = Math.min(100 * elHeight / windowHeight, 50);
		if (elHeight < windowHeight / 3) {
			proportion = 35;
		}
		if (translateYOut !== 0) {
			addScene(element, 0, proportion, {translateY: 1}, {translateY: translateYOut});
		}
		if (translateXOut !== 0) {
			addScene(element, 0, proportion, {translateX: 1}, {translateX: translateXOut});
		}
		if (opacityOut !== 1) {
			addScene(element, 0, proportion, {opacity: 1}, {opacity: opacityOut});
		}
		if (translateYIn !== 0) {
			addScene(element, 1, proportion, {translateY: translateYIn}, {translateY: 1});
		}
		if (translateXIn !== 0) {
			addScene(element, 1, proportion, {translateX: translateXIn}, {translateX: 1});
		}
		if (opacityIn !== 1) {
			addScene(element, 1, proportion, {opacity: opacityIn}, {opacity: 1});
		}

	});
}

function addScene(element, hook, duration, from, to) {
	to.ease = 'none';
	new ScrollMagic.Scene({
		triggerElement: element,
		triggerHook: hook,
		duration: duration + "%"
	}).setTween(
		TweenMax.fromTo(element.children, .5, from, to)
	).addTo(controller);
}
