import $ from "jquery";

$( () => {
    const tabs = document.getElementsByClassName('tab');
    for (let i = 0; i < tabs.length; i++) {
        tabs[i].addEventListener('toggle', toggleTabsHandler, true);
        tabs[i].addEventListener('mouseenter', newBackgroundHandler);
    }

    function toggleTabsHandler(event) {
        const parent = event.target.closest('.tabs');
        if (!parent) return;
        const tabs = parent.getElementsByClassName('tab');
        if (!event.target.hasAttribute('open')) {
            if (Array.from(tabs).filter(elem => elem.matches('[open]')).length === 0) {
                event.target.setAttribute('open','');
            }
            return;
        }
        let index = null;
        for (let i = 0; i < tabs.length; i++) {
            if (tabs[i] === event.target) {
                index = i;
                continue;
            }
            tabs[i].removeAttribute('open');
        }
        switchBackground(event.target.closest('.container-wrapper'), index);
    }

    function newBackgroundHandler(event) {
        event.target.classList.add('active');
        const parent = event.target.closest('.tabs');
        if (!parent) return;
        const tabs = parent.getElementsByClassName('tab');
        let index = 0;
        for (let i = 0; i < tabs.length; i++) {
            if (tabs[i] === event.target) {
                index = i;
                break;
            }
        }
        switchBackground(event.target.closest('.container-wrapper'), index);
        event.target.addEventListener('mouseleave', resetBackgroundHandler, {once: true});
    }

    function resetBackgroundHandler(event) {
        event.target.classList.remove('active');
        const parent = event.target.closest('.tabs');
        if (!parent) return;
        const opened = parent.querySelector('.tab[open]');
        const tabs = parent.getElementsByClassName('tab');
        let index = 0;
        for (let i = 0; i < tabs.length; i++) {
            if (tabs[i] === opened) {
                index = i;
                break;
            }
        }
        switchBackground(event.target.closest('.container-wrapper'), index);
    }

    function switchBackground(wrapper, index) {
        if (!wrapper) return;
        const backgroundImages = wrapper.querySelectorAll('.container-bg img');
        if (backgroundImages.length === 0) return;
        for (let i = 0; i < backgroundImages.length; i++) {
            backgroundImages[i].setAttribute('data-hidden', '');
        }
        backgroundImages[index].removeAttribute('data-hidden');
    }
});